<template>
  <en-drawer title="工单信息" :model-value="modelValue" @close="$emit('update:model-value', false)">
    <form-fields
      code="SI"
      :model="form.data"
      :rules="form.rules"
      :ref="setRef('form')"
      :loading="form.loading"
      :config="{
        SERVICE_ADVISOR: { prop: 'advisor.id' },
        SERVICE_MAINTENANCE_CATEGORY: { prop: 'serviceCategory.id' },
        SERVICE_SALESMAN: { visible: false }
      }"
    >
      <template #SERVICE_ADVISOR>
        <select-maintain
          v-model="form.data.advisor"
          :ajax="{
            action: 'GET /enocloud/common/advisorteam/advisor',
            params: (params, value) => (params.payload = { name: value }),
            convert: (data) => data.map((item) => item.user)
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </template>

      <template #SERVICE_SENDER>
        <en-input v-model="form.data.sender"></en-input>
      </template>

      <template #SERVICE_SENDER_CELLPHONE>
        <en-input v-model="form.data.senderTelephone"></en-input>
      </template>

      <template #SERVICE_SENDER_IDENTITY_CARD>
        <en-input v-model="form.data.senderIdentityCard"></en-input>
      </template>

      <template #SERVICE_MAINTENANCE_CATEGORY>
        <select-maintain
          v-model="form.data.serviceCategory"
          :ajax="{
            action: 'GET /enocloud/common/service/category',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </template>

      <template #SERVICE_ENTER_DATE_TIME>
        <en-date-picker v-model="form.data.enterDatetime" type="datetime" value-format="YYYY-MM-DDThh:mm:ss" class="w-full"></en-date-picker>
      </template>

      <template #SERVICE_EXPECTED_COMPLETION_DATE_TIME>
        <en-date-picker
          v-model="form.data.expectedCompletionDatetime"
          type="datetime"
          value-format="YYYY-MM-DDThh:mm:ss"
          class="w-full"
        ></en-date-picker>
      </template>

      <template #SERVICE_SALESMAN_TYPE>
        <select-maintain
          v-model="form.data.salesmanType"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['CLKTYPE'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </template>

      <en-form-item v-if="form.data.salesmanType" label="工单介绍人" prop="salesman">
        <template v-if="['C', 'U'].includes(form.data.salesmanType.code)">
          <select-maintain
            v-model="form.data.salesman"
            :ajax="{
              action: 'GET /enocloud/common/clerk',
              params: (params, value) => (params.payload = { code: form.data.salesmanType?.code, quickSearch: value })
            }"
            :props="{ label: 'name', value: '' }"
            value-key="id"
            remote
            class="w-full"
          ></select-maintain>
        </template>
        <template v-else-if="['O', 'I'].includes(form.data.salesmanType.code)">
          <select-maintain
            :model-value="form.data.salesman?.name"
            :ajax="{
              action: 'GET /enocloud/common/hint/:hintTypeStr',
              params: (params) => (params.paths = [form.data.salesmanType?.code === 'O' ? 'SALSMAN' : 'CUSISNSSMAN'])
            }"
            :props="{ label: 'name', value: 'name' }"
            allow-create
            @change="form.salesman.change"
            class="w-full"
          ></select-maintain>
        </template>
        <template v-else>
          <en-input disabled></en-input>
        </template>
      </en-form-item>
    </form-fields>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax type="primary" :method="footer.submit.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { FormFields } from '@enocloud/business'

let _this: any
export default factory({
  components: { FormFields },

  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceDto']>,
    vehicle: Object as PropType<Record<string, any>>,
    customer: Object as PropType<Record<string, any>>,
    vehicleServiceGroup: Object as PropType<EnocloudServiceDefinitions['VehicleServiceGroupDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id?: number) => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          if (this.data?.id) {
            this.form.init()
            this.form.data.id = this.data.id
            this.form.get()
          } else if (this.vehicle?.id && this.customer?.id) {
            this.form.init()
            this.form.data.vehicle = { ...this.vehicle } as EnocloudServiceDefinitions['VehicleDto']
            this.form.data.customer = { ...this.customer } as EnocloudServiceDefinitions['CustomerDto']
            this.form.data.vehicleServiceGroup = this.vehicleServiceGroup
            this.form.data.settlementMethod = ''
          }
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          'advisor.id': [{ required: true, message: '请选择服务顾问' }],
          'serviceCategory.id': [{ required: true, message: '请选择维修类别' }],
          salesman: [
            {
              validator(rule, value, callback) {
                if (!_this.form.data.salesmanType?.code || _this.form.data.salesmanType?.code === 'N') callback()
                else if (['C', 'U'].includes(_this.form.data.salesmanType.code) && !value?.id) {
                  callback(new Error('请选择工单介绍人'))
                } else if (['O', 'I'].includes(_this.form.data.salesmanType.code) && !value?.name) {
                  callback(new Error('请填写工单介绍人'))
                } else {
                  callback()
                }
              }
            }
          ]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/service',
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/service',
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          salesman: {
            change(value: string) {
              if (typeof value === 'string') {
                this.form.data.salesman = { name: value }
              }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        submit: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.footer.cancel.click()
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  },

  mounted() {
    _this = this
  }
})
</script>
